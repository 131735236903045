

.nav-link{
    font-size: 1.3rem !important;
}

.navbar-brand {
    font-size: 1.6rem!important;
}

.logo{
    cursor: pointer;
}