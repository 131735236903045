.input_UserMen{
    width: 50vw;
    border: none;
    border-radius: 0.2rem;
}

.col-md-8{
    display: flex;
    justify-content: center;
    padding: 0.5rem;
}

.text_userMenu{
padding: 3rem;

}