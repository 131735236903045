.container_login {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
}

.container_text {
    display: flex;
    flex-direction: column;
    width: 30rem;
    ;
    gap: 1.3rem;
    margin-top: 5vh;
}

.text_log {
    align-self: center;
    text-align: justify;
}

.input_log {
    width: 100%;
    border-radius: 0.5rem;
    text-align: center;
    padding: 0.5rem;
}

.p_error {
    color: rgb(246, 7, 7);
}

@media (max-width: 1199.98px) {
    .container_login {
        display: flex;
        height: 100vh;
        flex-direction: column;
        align-items: center;
    }

    .container_text {
        display: flex;
        flex-direction: column;
        width: 30rem;
        ;
        gap: 1.3rem;
        margin-top: 5vh;
    }

    .text_log {
        align-self: center;
        text-align: justify;
    }

    .input_log {
        width: 100%;
        border-radius: 0.5rem;
        text-align: center;
        padding: 0.5rem;
    }

}


@media (max-width:576px) {

    .container_login {
        display: flex;
        height: 100vh;
        flex-direction: column;
        align-items: center;
    }

    .container_text {
        display: flex;
        flex-direction: column;
        width: 20rem;
        gap: 1.3rem;
        margin-top: 5vh;
    }

    .text_log {
        align-self: center;
        text-align: justify;
    }

    .input_log {
        width: 100%;
        border-radius: 0.5rem;
        text-align: center;
        padding: 0.5rem;
    }



}

@media (max-width:360px) {

    .container_login {
        display: flex;
        height: 100vh;
        flex-direction: column;
        align-items: center;
    }

    .container_text {
        display: flex;
        flex-direction: column;
        width: 15rem;
        gap: 1.3rem;
        margin-top: 5vh;
    }

    .text_log {
        align-self: center;
        text-align: justify;
    }

    .input_log {
        width: 100%;
        border-radius: 0.5rem;
        text-align: center;
        padding: 0.5rem;
    }



}