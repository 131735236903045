.justify-content-md-center {
    padding-top: 1rem;
    padding-bottom: 1rem;
    align-items: center;
    justify-items: center;
}
.col-md-5{
    justify-items: center;
}
.text_Home {
    font-size: 10vh;
}

.container_input {
    display: flex;
    justify-content: center;
    justify-items: center;
    padding: 1.5rem;
    background-color: rgb(230, 230, 230);
    align-items: center;
    gap: 2rem;
}

.input_1 {
    width: 50vw;
    border-radius: 0.5rem;
    padding: 0.7vw
}

.col-md-10{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}


.col-md-4 {
    display: flex;
    justify-content: start;
    align-items: center;
}

.container_short{
    padding: 0.5rem;
}

.short_url{
    color: blue;
    font-size: 1.2rem;
    cursor: pointer;
}

.logo_home{
    width: 50%;
    height: auto;
}

@supports(object-fit: cover){
    .logo_home{
      height: 50%;
      object-fit: cover;
      object-position: center center;
    }
}

@media (max-width: 1199.98px) {

    .logo_home{
        width: 17rem;
    }
    

    .text_Home {
        font-size: 10vh;
    }

    .container_input {
        display: flex;
        justify-content: center;
        justify-items: center;
        padding: 1.5rem;
        background-color: rgb(230, 230, 230);
        align-items: center;
        gap: 2rem;
    }

    .short_url{
        color: blue;
        font-size: 1.2rem;
        cursor: pointer;
    }
    
}

@media (max-width:991.98px) {

    .logo_home{
        width: 17rem;
    }
    

    .text_Home {
        font-size: 8vh;
    }

    .container_input {
        display: flex;
        justify-content: center;
        justify-items: center;
        padding: 1.5rem;
        background-color: rgb(230, 230, 230);
        align-items: center;
        gap: 2rem;
    }

    .short_url{
        color: blue;
        font-size: 1.2rem;
        cursor: pointer;
    }
    
}

@media (max-width:767.98px) {

    .logo_home{
        width: 14rem;
    }
    

    .text_Home {
        font-size: 8vh;
    }

    .container_input {
        display: flex;
        justify-content: center;
        justify-items: center;
        padding: 1.5rem;
        background-color: rgb(230, 230, 230);
        align-items: center;
        gap: 2rem;
    }

    .short_url{
        color: blue;
        font-size: 1.2rem;
        cursor: pointer;
    }
    
}

@media (max-width:576px) {

    .logo_home{
        width: 10rem;
    }
    

    .text_Home {
        font-size: 5vh;
    }

    .container_input {
        display: flex;
        justify-content: center;
        justify-items: center;
        padding: 1.5rem;
        background-color: rgb(230, 230, 230);
        align-items: center;
        gap: 2rem;
    }

    .short_url{
        color: blue;
        font-size: 1.2rem;
        cursor: pointer;
    }
    
}

